import React from "react"

import styles from "./splitLayout.module.scss"

const SplitLayout = ({children}) => {
  return (
    <div className={styles.splitLayout}>
      {children}
    </div>
  )
}

export default SplitLayout
