import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import { linkFormat } from "../../helpers/links"

import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import SecondNav from "../../modules/secondNav"
import SplitLayout from "../../modules/splitLayout"
import Hero from "../../modules/hero"
import SimpleHero from "../../modules/simpleHero"
import PageBuilder from "../../components/page-builder"
import ConditionalWrapper from "../../components/conditionalWrapper"
import Form from "../../modules/form"
import ContactLayout from "../../modules/contactLayout"

export const query = graphql`
  query($id: String) {
    sanityProduct(_id: { eq: $id }) {
      title
      slug
      hero {
        heading
      }
      secondaryNav {
        structure {
          url
          title
          bold
          before
          _key
        }
      }
      pageLayout
      formTitle
      _rawFormSubtitle
      formLink
      _rawHero(resolveReferences: { maxDepth: 10 })
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawParent(resolveReferences: { maxDepth: 10 })
      _rawIcon(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const getMainProduct = page => {
  if (page?._rawParent) {
    return getMainProduct(page._rawParent)
  }

  return {
    title: page.title,
    slug: page.slug,
  }
}

export default props => {
  const page = props?.data?.sanityProduct
  const mainProduct = getMainProduct(page)
  const isContactPage = page.pageLayout === "Contact Page"
  const splitLayout = page.pageLayout === "Split Layout"

  const seo = {
    title: page.title,
    customTitle: page.seo?.metaTitle,
    description: page.seo?.description,
    image: page.seo?.openGraphImage?.asset?.url,
    noIndex: page.seo?.noIndex,
  }

  if (isContactPage) return <ContactLayout seo={seo} page={page} />

  return (
    <>
      <Layout>
        <Helmet>
          {seo?.noIndex && <meta name="robots" content="noindex" />}
        </Helmet>
        <SEO
          title={seo.title}
          description={seo.description}
          image={seo.image}
          customTitle={seo.customTitle}
        />
        {page.secondaryNav && (
          <>
            <Breadcrumbs alt location={props.location}>
              <Link to={`/products/${mainProduct.slug}/`}>
                <span>{mainProduct.title}</span>
              </Link>
              {page.secondaryNav.structure.map(item => (
                <Link
                  key={item._key}
                  state={{ bold: !!item.bold, before: !!item.before }}
                  to={linkFormat(item.url)}
                >
                  {item.title}
                </Link>
              ))}
            </Breadcrumbs>
            <SecondNav nav={page.secondaryNav.structure} product={mainProduct}>
              <em>{mainProduct.title}</em>
            </SecondNav>
          </>
        )}
        {(page._rawContent || page._rawHero || page.formLink) && (
          <ConditionalWrapper
            condition={splitLayout}
            wrapper={children => <SplitLayout>{children}</SplitLayout>}
          >
            <>
              {!splitLayout && page._rawHero && (
                <Hero
                  {...page._rawHero}
                  tagline={page._rawHero && page._rawHero.tagline}
                  bg={page.icon}
                  ctas={page._rawHero.ctas}
                  isProduct
                />
              )}
              <div>
                {splitLayout && page._rawHero && (
                  <SimpleHero
                    {...page.hero}
                    tagline={page._rawHero && page._rawHero.tagline}
                    ctas={page._rawHero.ctas}
                    splitLayout
                    withoutBg
                  />
                )}
                {page._rawContent && <PageBuilder blocks={page._rawContent} />}
              </div>
              {page.formLink && (
                <Form
                  title={page.formTitle}
                  link={page.formLink}
                  split={splitLayout}
                  subtitle={page._rawFormSubtitle}
                />
              )}
            </>
          </ConditionalWrapper>
        )}
      </Layout>
    </>
  )
}
